import VHfix from './modules/VHfix'
import Navigation from './modules/Navigation'
import BookButtonRevealImages from './modules/Book'
import ScrollDirection from './modules/ScrollDirection'
import BodyBookButton from './modules/BodyBookButton'
import PageMask from './modules/PageMask'
import TableOverflow from './modules/TableOverflow'

new PageMask()
new BodyBookButton()
new ScrollDirection()
new VHfix()
new Navigation()
new BookButtonRevealImages()

$('.s-entry > table').each(function() {
    new TableOverflow(this)
})