import $ from 'jquery'

class Navigation {
    constructor() {
        this.menuEl = $('.c-nav-primary')
        this.bookEl = $('.c-page-header__book-button')
        this.subMenus = this.menuEl.find('.sub-menu')
        this.menuItemsWithChildren = this.menuEl.find('.menu__item--has-children > a')
        this.activeMenuItems = this.menuEl.find('.menu__item--ancestor')

        this.bookEl.on('click', function () {
            $(window).trigger('loadResDiaryWidget');
        });

        this.createBackButtons()

        // Handle clicking a menu item with children
        this.menuItemsWithChildren.on('click', this.revealSubMenu)

        // Handle clicking a sub menu back button
        this.menuEl.on('click', '.menu__button', this.hideSubMenu)

        // Reset the offcanvas state when the offcanvas is closed
        $('.js-offcanvas-close').on('click', () => this.resetState())

    }

    revealSubMenu(event) {
        event.preventDefault()
        let menuItem = $(event.target).parent()

        $('.sub-menu').removeClass('is-active')
        $('body').removeClass('submenu--is-active')

        menuItem.find('> .sub-menu').addClass('is-active')
        $('body').addClass('submenu--is-active')
    }

    hideSubMenu(event) {
        event.preventDefault()
        $('body').removeClass('submenu--is-active')
        let subMenu = $(event.target).parent()
        subMenu.removeClass('is-active')
    }

    createBackButtons() {
        this.subMenus.each(function () {
            $(this).prepend('<button class="menu__button"></button>')
        })
    }

    resetState() {
        setTimeout(() => {
            this.menuItemsWithChildren.parent().removeClass('is-active')
        }, 1000)
    }
}

export default Navigation