import $ from 'jquery'

class BookButtonRevealImages {
    constructor() {
        this.bookButton = $('.js-bookButton')

        // Handle offcanvas image rendering
        this.bookButton.on('click', this.revealOffcanvas)
    }

    revealOffcanvas() {
        $('body').trigger('revealOffcanvas');
    }

}

export default BookButtonRevealImages