class VHfix {
    constructor() {

        $(window).on('resize', () => {
            // Get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            
            // Sset the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }).trigger('resize');
    }
}

export default VHfix