import $ from 'jquery'

class BodyBookButton {
    constructor() {
        this.bookButton = $('.c-button--booking')

        // Handle offcanvas image rendering
        this.bookButton.on('click', this.revealOffcanvas)

        $('.js-booking-form').attr("target", "_blank");
    }

    revealOffcanvas(event) {
        event.preventDefault()
        $('body').addClass('book-offcanvas-is-active');
        $('.c-tabbed-content--offcanvas .c-tabbed-content__tabs li').removeClass('is-active');
        $('.c-tabbed-content--offcanvas .c-tabbed-content__tabs li a').removeClass('is-active');
        $('.c-tabbed-content--offcanvas .c-tabbed-content__tabs li:last-of-type').addClass('is-active');
        $('.c-tabbed-content--offcanvas .c-tabbed-content__tabs li:last-of-type a').addClass('is-active');

        $('.c-tabbed-content--offcanvas .c-tabbed-content__tab').removeClass('is-active');
        $('.c-tabbed-content--offcanvas .c-tabbed-content__tab').hide()

        $('.c-tabbed-content--offcanvas .c-tabbed-content__tab:last-of-type').addClass('is-active');
        $('.c-tabbed-content--offcanvas .c-tabbed-content__tab:last-of-type').show()

        $('body').trigger('revealOffcanvas');
    }

}

export default BodyBookButton